<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Attorneys <small>(Financial Decisions)</small></h3>

    <PartnerPropertyPrimaryAttorneyYN
        v-if="partner"
        v-model="form.data.attorneys.property.details.primaryPartner"
    />

    <template v-if="([true, false].includes(details.primaryPartner) || !partner)">
      <div class="question-box with-arrow">
        <PrimaryPropertyAttorneys
            v-model="form.data.attorneys.property.details.primary"
            :details="form.data.attorneys.property.details"
            :optionsData="primaryOptions"
        />
      </div>
    </template>

    <BackupPropertyAttorneysYN
        v-if="form.data.attorneys.property.details.primary.length || form.data.attorneys.property.details.backupYN"
        v-model="form.data.attorneys.property.details.backupYN"
        :details="form.data.attorneys.property.details"/>

    <template v-if="form.data.attorneys.property.details.backupYN" >
      <div class="question-box with-arrow">
        <BackupPropertyAttorneys
            v-model="form.data.attorneys.property.details.backup"
            :details="form.data.attorneys.property.details"
            :optionsData="backupOptions"
        />
      </div>
    </template>


  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import {lpaPartnerHelpers} from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaPartnerHelpers";
import {lpaAttorneyHelpers} from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaAttorneyHelpers";

import BackupPropertyAttorneysYN
  from "@/components/common/questionnaires/question/questions/lpa/attorneys/property/BackupPropertyAttorneysYN";
import PartnerPropertyPrimaryAttorneyYN
  from "@/components/common/questionnaires/question/questions/lpa/attorneys/property/PartnerPropertyPrimaryAttorneyYN";
import PrimaryPropertyAttorneys
  from "@/components/common/questionnaires/question/questions/lpa/attorneys/property/PrimaryPropertyAttorneys";
import BackupPropertyAttorneys
  from "@/components/common/questionnaires/question/questions/lpa/attorneys/property/BackupPropertyAttorneys";


export default {
  name: 'PropertyAttorneys',
  mixins: [fieldsetHelpers, lpaPartnerHelpers, lpaAttorneyHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    BackupPropertyAttorneys,
    PrimaryPropertyAttorneys,
    PartnerPropertyPrimaryAttorneyYN,
    BackupPropertyAttorneysYN
  },
  data() {
    return {
      label: 'Attorneys (Financial)', // used for steps progress
      loadCondition: this.showFieldset, // used to conditionally load the fieldset uses
      // must be a function (this.form for arguments to function supplied)
      primaryOptions: [],
      backupOptions: [],
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'details',
        formPath: 'data.attorneys.property.details'
      }
    }
  },
  methods: {
    showFieldset() {
      return this.form.data.details.options.enabled.property
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    details() {
      // required for attorneys helpers file
      return this.form.data.attorneys.property.details
    },
    data() {
      return this.form.data.attorneys.property.details
    }
  }
};
</script>
