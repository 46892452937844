<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="workTogether"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
        class="radio-options-wide"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { lpaOptions} from "@/components/common/questionnaires/question/options/lpaOptions";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

export default {
  name: 'OptionPropertyAttorneysPrimaryWorkTogetherYN',
  mixins: [lpaOptions, questionLoadHelpers, personHelpers, textHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [String],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'How should your primary attorneys work together?',
        subTitle: `Where Attorneys are appointed 'Jointly' they must agree on every decision (decisions could be delayed because it takes time to get the attorneys together). Also, if one attorney dies or can no longer act, all your attorneys become unable to act. Where your Attorneys are appointed 'Jointly and Severally'. This means that they can make decisions on their own or together. Most people choose this option because it’s the most practical.`,
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyPrimaryPropertyWorkTogetherYN', // fake field name required
        formPath: 'data.attorneys.property.details.primaryOptions.work_together',
        jsonSaveField: 'work_together'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
