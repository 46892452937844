<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Options <small>(Financial Decisions)</small></h3>

    <OptionPropertyAuthorityYN v-model="form.data.attorneys.property.details.generalOptions.authority_when" />

    <OptionPropertyAttorneysPrimaryWorkTogetherYN v-if="form.data.attorneys.property.details.primary.length > 1"
                                                  v-model="form.data.attorneys.property.details.primaryOptions.work_together"

    />
    <transition name="fade">
      <div v-if="form.data.attorneys.property.details.primaryOptions.work_together === 'joint_separate_other'"
           class="question-box with-arrow">
        <OptionPropertyAttorneysPrimaryWorkTogetherOther v-model="form.data.attorneys.property.details.primaryOptions.work_together_other_details"

        />
      </div>
    </transition>


    <OptionPropertyAttorneysBackupWorkTogetherYN v-if="form.data.attorneys.property.details.backup.length > 1"
                                                 v-model="form.data.attorneys.property.details.backupOptions.work_together" />
    <transition name="fade">
      <div v-if="form.data.attorneys.property.details.backupOptions.work_together === 'joint_separate_other'"
           class="question-box with-arrow">
        <OptionPropertyAttorneysBackupWorkTogetherOther v-model="form.data.attorneys.property.details.backupOptions.work_together_other_details"

        />
      </div>
    </transition>

    <OptionPropertyInstructionsYN v-model="form.data.attorneys.property.details.generalOptions.instructionsYN" />
    <transition name="fade">
      <div v-if="form.data.attorneys.property.details.generalOptions.instructionsYN"
           class="question-box with-arrow">
        <OptionsPropertyInstructions v-model="form.data.attorneys.property.details.generalOptions.instructions" />
      </div>
    </transition>

    <OptionPropertyPreferencesYN v-model="form.data.attorneys.property.details.generalOptions.preferencesYN" />
    <transition name="fade">
      <div v-if="form.data.attorneys.property.details.generalOptions.preferencesYN"
           class="question-box with-arrow">
        <OptionsPropertyPreferences v-model="form.data.attorneys.property.details.generalOptions.preferences" />
      </div>
    </transition>

    <NotifiedPeoplePropertyYN v-model="form.data.notifiedPeople.property.details.enabled" />

    <template v-if="form.data.notifiedPeople.property.details.enabled">
      <div class="question-box with-arrow">
        <NotifiedPeopleProperty
            v-model="form.data.notifiedPeople.property.details.primary"
        />
      </div>
    </template>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import OptionPropertyAuthorityYN from "@/components/common/questionnaires/question/questions/lpa/options/property/OptionPropertyAuthorityYN";
import OptionPropertyAttorneysPrimaryWorkTogetherYN from "@/components/common/questionnaires/question/questions/lpa/options/property/OptionPropertyAttorneysPrimaryWorkTogetherYN";
import OptionPropertyAttorneysPrimaryWorkTogetherOther from "@/components/common/questionnaires/question/questions/lpa/options/property/OptionPropertyAttorneysPrimaryWorkTogetherOther";
import OptionPropertyAttorneysBackupWorkTogetherYN from "@/components/common/questionnaires/question/questions/lpa/options/property/OptionPropertyAttorneysBackupWorkTogetherYN";
import OptionPropertyAttorneysBackupWorkTogetherOther from "@/components/common/questionnaires/question/questions/lpa/options/property/OptionPropertyAttorneysBackupWorkTogetherOther";
import OptionPropertyInstructionsYN from "@/components/common/questionnaires/question/questions/lpa/options/property/OptionPropertyInstructionsYN";
import OptionsPropertyInstructions from "@/components/common/questionnaires/question/questions/lpa/options/property/OptionPropertyInstructions";
import OptionPropertyPreferencesYN from "@/components/common/questionnaires/question/questions/lpa/options/property/OptionPropertyPreferencesYN";
import OptionsPropertyPreferences from "@/components/common/questionnaires/question/questions/lpa/options/property/OptionPropertyPreferences";
import NotifiedPeoplePropertyYN
  from "@/components/common/questionnaires/question/questions/lpa/notifiedPeople/property/NotifiedPeoplePropertyYN";
import NotifiedPeopleProperty
  from "@/components/common/questionnaires/question/questions/lpa/notifiedPeople/property/NotifiedPeopleProperty";


export default {
  name: 'PropertyOptions',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    NotifiedPeopleProperty,
    NotifiedPeoplePropertyYN,
    OptionsPropertyPreferences,
    OptionPropertyPreferencesYN,
    OptionsPropertyInstructions,
    OptionPropertyInstructionsYN,
    OptionPropertyAttorneysBackupWorkTogetherOther,
    OptionPropertyAttorneysBackupWorkTogetherYN,
    OptionPropertyAttorneysPrimaryWorkTogetherOther,
    OptionPropertyAttorneysPrimaryWorkTogetherYN,
    OptionPropertyAuthorityYN
  },
  data() {
    return {
      label: 'Options (Financial)', // used for steps progress
      loadCondition: this.showFieldset, // used to conditionally load the fieldset uses
      // must be a function (this.form for arguments to function supplied)
    }
  },
  methods: {
    showFieldset() {
      return this.form.data.details.options.enabled.property
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
