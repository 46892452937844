<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="finishFunction">

    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>

      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import HealthAttorneys from "@/components/common/questionnaires/fieldsets/lpa/health/HealthAttorneys";
import HealthCertificateProvider
  from "@/components/common/questionnaires/fieldsets/lpa/health/HealthCertificateProvider";
import HealthOptions from "@/components/common/questionnaires/fieldsets/lpa/health/HealthOptions";

import PropertyAttorneys from "@/components/common/questionnaires/fieldsets/lpa/property/PropertyAttorneys";
import PropertyCertificateProvider
  from "@/components/common/questionnaires/fieldsets/lpa/property/PropertyCertificateProvider";
import PropertyOptions from "@/components/common/questionnaires/fieldsets/lpa/property/PropertyOptions";

import {httpQuestionnaire} from "@/services";


export default {
  name: "LpaFull",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    HealthAttorneys,
    HealthCertificateProvider,
    HealthOptions,
    PropertyAttorneys,
    PropertyCertificateProvider,
    PropertyOptions
  },
  data() {
    return {
      name: 'LpaFull',
      title: "LPA Instructions",
      url: 'estate_planning_lpa',
      order:
          [
            'HealthAttorneys',
            'HealthCertificateProvider',
            'HealthOptions',
            'PropertyAttorneys',
            'PropertyCertificateProvider',
            'PropertyOptions'
          ],
      finishFunction: this.instructionsTaken,
      expiryAccessCodeOnFinish: true
    }
  },

  methods: {
    async instructionsTaken() {
      return httpQuestionnaire
          .patch(this.url, {
            lpa_id: this.form.id,
            status: "awaiting_checking",
          })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }
};
</script>
