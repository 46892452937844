<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Certificate Provider <small>(Financial Decisions)</small></h3>

    <div class="question-box with-arrow">
      <CertificateProviderProperty
          v-model="form.data.certificateProviders.property.details.primary"
          :attorneys="form.data.attorneys"
      />
    </div>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import CertificateProviderProperty
  from "@/components/common/questionnaires/question/questions/lpa/certificateProviders/property/CertificateProviderProperty";


export default {
  name: 'PropertyCertificateProvider',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    CertificateProviderProperty
  },
  data() {
    return {
      label: 'Certificate (Financial)', // used for steps progress
      loadCondition: this.showFieldset, // used to conditionally load the fieldset uses
      // must be a function (this.form for arguments to function supplied)
    }
  },
  methods: {
    showFieldset() {
      return this.form.data.details.options.enabled.property
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
