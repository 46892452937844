var render = function render(){var _vm=this,_c=_vm._self._c;return _c('QuestionBase',{attrs:{"id":_vm.db.saveField,"errors":[..._vm.jsErrors],"question":_vm.question,"valid":!!_vm.data.length}},[_c('div',{staticClass:"accordion sub-items"},[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.data),function(person,index){return _c('ObjectCard',{key:'attorneyPropertyPrimaryData' + person.id + index,ref:'attorneyPropertyPrimaryData' + index,refInFor:true,attrs:{"id":'attorneyPropertyPrimaryData' + index,"select-mode":true,"index":index,"selected":true,"title":_vm.cardTitle(person),"value":_vm.data[index],"show-delete":false,"show-save":!person.placeholder,"type":"attorneyPropertyPrimaryData","loading":_vm.loading},on:{"click":function($event){return _vm.deselectConfirm(
                person,
                'Remove person from your primary Attorneys?'
              )},"delete":function($event){return _vm.deletePerson(person.id)},"save":function($event){return _vm.savePerson(
            null,
            'attorneyPropertyPrimaryData' + index)}}},[(person.type === 'person')?_c('PersonLpa',{key:'attorneyPropertyPrimary-form-data' + person.id,attrs:{"errors-post":_vm.errorsPatch,"no-save":true,"objectId":person.id},on:{"save":function($event){return _vm.savePersonForm($event, person)}},model:{value:(_vm.data[index]),callback:function ($$v) {_vm.$set(_vm.data, index, $$v)},expression:"data[index]"}}):_vm._e()],1)}),1)],1),_c('transition',{attrs:{"name":"fade"}},[(
        ((!_vm.details.primaryPartner) ||
        (!_vm.partner)) && _vm.data.length < _vm.maxAllowedPeople
      )?[_c('b-button',{staticClass:"btn-question w-100",on:{"click":function($event){_vm.show.addPerson = true}}},[_c('i',{staticClass:"i-Add text-25 font-weight-800 mr-2"}),_vm._v(" Add Attorney ")])]:_vm._e()],2),_c('LpaPeopleModal',{attrs:{"options":_vm.optionsData,"dataSelected":_vm.data,"show-charities":false,"show-groups":false,"sub-title":'Select attorneys by selecting existing people or adding new people. You can select multiple individuals for this role.',"hide-type-options":true,"disabledPersonFunction":_vm.disableSelectPersonFunction,"maximum-allowed-selection":_vm.maxAllowedPeople - _vm.data.length,"maximumAllowedWarningText":"Maximum number of Primary Attorneys selected","warningMessage":"Person must be over 18 years old","title":"Add Primary Attorney"},on:{"dataSelectedUpdate":function($event){_vm.data=$event},"save":_vm.save},model:{value:(_vm.show.addPerson),callback:function ($$v) {_vm.$set(_vm.show, "addPerson", $$v)},expression:"show.addPerson"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }