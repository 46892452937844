<template>
  <QuestionBase
      :id="db.saveField"
      :errors="[...jsErrors]"
      :question="question"

      :valid="!!data.length"
  >
    <!-- Selected People -->
    <div class="accordion sub-items">
      <transition-group name="fade">
        <ObjectCard
            v-for="(person, index) in data"
            :id="'notifiedPeoplePropertyData' + index"
            :key="'notifiedPeoplePropertyData' + person.id + index"
            :ref="'notifiedPeoplePropertyData' + index"
            :select-mode="true"
            :index="index"
            :selected="true"
            :title="cardTitle(person)"
            :value="data[index]"
            :show-delete="false"
            :show-save="!person.placeholder"
            type="notifiedPeoplePropertyData"
            :loading="loading"
            @click="deselectConfirm(
                  person,
                  'Remove person from your notified people?'
                )
          "
            @delete="deletePerson(person.id)"
            @save="
            savePerson(
              null,
              'notifiedPeoplePropertyData' + index)"
        >
          <PersonLpa
              v-if="person.type === 'person'"
              v-model="data[index]"
              :key="'notifiedPeopleProperty-form-data' + person.id"
              :errors-post="errorsPatch"
              :no-save="true"
              :objectId="person.id"
              @save="savePersonForm($event, person)"
          />

        </ObjectCard>
      </transition-group>
    </div>

    <!-- New People -->
    <transition name="fade">
      <template v-if="data.length < maxAllowedPeople">
        <b-button
            class="btn-question w-100"
            @click="show.addPerson = true"
        >
          <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Notified Person
        </b-button>
      </template>
    </transition>

    <LpaPeopleModal
        v-model="show.addPerson"
        :options="options"
        :dataSelected="data"
        :show-charities="false"
        :show-groups="false"
        title="Add Notified Person"
        :disabledPersonFunction="disableSelectPersonFunction"
        :hide-type-options="true"
        :maximum-allowed-selection="maxAllowedPeople - data.length"
        maximumAllowedWarningText="Maximum number of Notified People selected"
        warningMessage="Person must be over 18 years old"
        @dataSelectedUpdate="data = $event"
        @save="save"
    />
  </QuestionBase>
</template>

<script>
import {dateFormat} from "@/mixins/dateFormat";
import {personHelpers} from "@/mixins/personHelpers";
import {peopleSaveHelpers} from "@/components/common/questionnaires/question/questions/objects/peopleSaveHelpers";
import {questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {lpaRelationshipHelpers} from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaRelationshipHelpers";
import {lpaPeopleHelpers} from "@/components/common/questionnaires/question/questions/lpa/helpers/lpaPeopleHelpers";
import {lpaPartnerHelpers} from "@/components/common/questionnaires/fieldsets/lpa/helpers/lpaPartnerHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";

import PersonLpa from "@/components/common/questionnaires/fieldsets/sub/people/PersonLpa";
import LpaPeopleModal from "@/components/common/questionnaires/question/questions/lpa/helpers/LpaPeopleModal";

export default {
  name: "NotifiedPeopleProperty",
  components: {
    LpaPeopleModal,
    PersonLpa,
    ObjectCard,
    QuestionBase,
  },
  mixins: [
    dateFormat,
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    lpaPeopleHelpers,
    lpaPartnerHelpers,
    lpaRelationshipHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false,
    }
  },
  data() {
    return {
      question: {
        title: 'Notified People for property instruction',
        subTitle: null,
        tip: null
      },
      db: {
        saveLocation: "product_lpa_data",
        saveField: "notifiedPeopleProperty",
        formPath: "data.notifiedPeople.property.details.primary",
        jsonSaveField: "primary",
        personSaveLocation: 'client_person_lpa'
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage:
            "You must have more than one Notified Person if enabled, click to select",
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false,
      },
      maxAllowedPeople: 5
    };
  },
  computed: {
    data: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    storePeople() {
      return this.$store.getters.people
    },
    options() {
      // notified people must be over 18
      return [...this.storePeople.map(person => {
        return this.convertPerson(person)
      }).filter(person => !this.isChild(person.dob)), this.placeholder]
    }
  },
  methods: {
    disableSelectPersonFunction(person) {
      return this.isChild(person.dob)
    }
  }
};
</script>
