<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="propertyOptions.authority"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
        class="radio-options-wide"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers} from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { lpaOptions} from "@/components/common/questionnaires/question/options/lpaOptions";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

export default {
  name: 'OptionPropertyAuthorityYN',
  mixins: [lpaOptions, questionLoadHelpers, personHelpers, textHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [String],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'Should attorneys be able to act (with your permission) where you have capacity?',
        subTitle: 'While you still have mental capacity, your attorneys can only act with your consent. If you later lose capacity, they can continue to act on your behalf. This is useful if you are able to make your own decisions but there’s another reason you want your attorneys to help you – for example, if you have a physical condition that makes it difficult to visit the bank, talk on the phone or sign documents.',
        tip: null
      },
      db: {
        saveLocation: 'product_lpa_data',
        saveField: 'attorneyPropertyAuthorityYN', // fake field name required
        formPath: 'data.attorneys.property.details.generalOptions.authority_when',
        jsonSaveField: 'authority_when'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
